import React from "react";
import Link from "next/link";
import "./MobileServices.scss";
import { Col, Divider, Row, Typography } from "antd";
import { CiLocationOn } from "react-icons/ci";
import {
  MdOutlineDescription,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { AiOutlineGift, AiOutlineHeart } from "react-icons/ai";
import { RiShieldKeyholeLine } from "react-icons/ri";
const { Text } = Typography;
export default function MobileServices() {
  return (
    <Col className="mobile-services">
      <Row>
        <Col span={24}>
          <Link href="https://sanaulla.ginkgoretail.net/ordertracking/011122155326663841">
            <Row justify="space-between" align="center" className="service">
              <Col span={2}>
                <CiLocationOn className="service-icon" />
              </Col>
              <Col span={20}>
                <Text className="service-text">Track Order</Text>
              </Col>
              {/* <Col span={2}><MdOutlineKeyboardArrowRight className='service-icon' /></Col> */}
            </Row>
          </Link>
        </Col>
        {/* <Divider/>
        <Col span={24}>
            <Row justify='space-between' align='center' className='service'>
                <Col span={2}><AiOutlineHeart className='service-icon'/></Col>
                <Col span={20}><Text className='service-text'>WishtList</Text></Col>
                <Col span={2}><MdOutlineKeyboardArrowRight className='service-icon'/></Col>
            </Row>
        </Col>
        <Divider/>
        <Col span={24}>
            <Row justify='space-between' align='center' className='service'>
                <Col span={2}><AiOutlineGift className='service-icon'/></Col>
                <Col span={20}><Text className='service-text'>Reward Point</Text></Col>
                <Col span={2}><MdOutlineKeyboardArrowRight className='service-icon'/></Col>
            </Row>
        </Col> */}
        <Divider />

        <Col span={24}>
          <Link href="https://help.sanaullastore.com/support/solutions">
            <Row justify="space-between" align="center" className="service">
              <Col span={2}>
                <CiLocationOn className="service-icon" />
              </Col>
              <Col span={20}>
                <Text className="service-text">FAQs</Text>
              </Col>
              {/* <Col span={2}><MdOutlineKeyboardArrowRight className='service-icon' /></Col> */}
            </Row>
          </Link>
        </Col>

        <Divider />
        <Col span={24}>
          <Link href={"/pages/static/about-us"}>
            <Row justify="space-between" align="center" className="service">
              <Col span={2}>
                <CiLocationOn className="service-icon" />
              </Col>
              <Col span={20}>
                <Text className="service-text">About Us</Text>
              </Col>
              {/* <Col span={2}><MdOutlineKeyboardArrowRight className='service-icon' /></Col> */}
            </Row>
          </Link>
        </Col>
        <Divider />
        <Col span={24}>
          <Link href="https://help.sanaullastore.com/support/solutions/articles/154000151846-terms-and-conditions">
            <Row justify="space-between" align="center" className="service">
              <Col span={2}>
                <MdOutlineDescription className="service-icon" />
              </Col>
              <Col span={20}>
                <Text className="service-text">Terms & Conditions</Text>
              </Col>
              {/* <Col span={2}><MdOutlineKeyboardArrowRight className='service-icon' /></Col> */}
            </Row>
          </Link>
        </Col>
        <Divider />
        <Col span={24}>
          <Link href={"/pages/static/privacy-policy"}>
            <Row justify="space-between" align="center" className="service">
              <Col span={2}>
                <RiShieldKeyholeLine className="service-icon" />
              </Col>
              <Col span={20}>
                <Text className="service-text">Privacy Policy</Text>
              </Col>
              {/* <Col span={2}><MdOutlineKeyboardArrowRight className='service-icon' /></Col> */}
            </Row>
          </Link>
        </Col>
      </Row>
    </Col>
  );
}
