"use client";

//? Library Imports ------------------------------------------------------------>
import { Spin } from "antd";
import Image from "next/image";
import LoaderImage from "../../../assets/images/Loader/Loader.svg";

//? Assets --------------------------------------------------------------------->
import "./Loader.scss";

export default function Loader(props) {
  return (
    <div className="spin">
      <Spin
        size={props?.size}
        indicator={<Image src={LoaderImage} alt="Loader" />}
      />
    </div>
  );
}

// Ant design spinner with custom image
