"use client";
//? Library Imports ------------------------------------------------------------>
import { Col } from "antd";
import { useRouter } from "next/navigation";
import LoginMobile from "./Modules/LoginMobile";
import AccountStatus from "@/functions/Cookies/Cookies";

export default function LoginPage() {
  //? Hooks ---------------------------------------------------------------------->
  const router = useRouter();
  const {
    loggedIn,
    comverse_customer_id,
    comverse_customer_email,
    comverse_customer_token,
  } = AccountStatus();


  return !loggedIn
    ? !loggedIn && (
      <Col style={{ backgroundColor: " #F4F4F6", maxWidth: "425px" }} className="login-container">
        <LoginMobile />
      </Col>
    )
    : router.push("/account");
}
