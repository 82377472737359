import Axios from "axios";

let forgetExpiryURL = '/storefront/check_forgot_expiry'
let setNewPasswordURL = '/storefront/set_password'
let forgetPasswordURL = '/storefront/forget_password'
let socialLoginURL = '/storefront/social_login'
const AccountApi = {
    checkForgotExpiry(body) {
        return Axios({
            url: process.env.NEXT_PUBLIC_BACKEND + forgetExpiryURL,
            method: "post",
            headers: {
                "content-type": "application/json",
            },
            data: body,
        }).then((response) => response);
    },
    setNewPassword(body) {
        return Axios({
            url: process.env.NEXT_PUBLIC_BACKEND + setNewPasswordURL,
            method: "post",
            headers: {
                "content-type": "application/json",
            },
            data: body,
        }).then((response) => response);
    },
    forgotPassword(body) {
        return Axios({
            url: process.env.NEXT_PUBLIC_BACKEND + forgetPasswordURL,
            method: "post",
            headers: {
                "content-type": "application/json",
            },
            data: body,
        }).then((response) => response);
    },
    socialLogin(body) {
        return Axios({
            url: process.env.NEXT_PUBLIC_BACKEND + socialLoginURL,
            method: "post",
            headers: {
                "content-type": "application/json",
            },
            data: body,
        }).then((response) => response);
    }
};

export default AccountApi;
