"use client";
//? Library Imports ----------------------------------------------------------->
import Cookies from "js-cookie";

//? Functions ------------------------------------------------------------------>
export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000); // Calculate expiration time

  let expires = "expires=" + d.toUTCString(); // Convert expiration time to UTC string

  // Set the cookie with the provided name, value, expiration, and path
  document.cookie =
    cname + "=" + encodeURIComponent(cvalue) + ";" + expires + ";path=/";
}

export function getCookieValue(cookieName) {
  // Split all the cookies into an array
  var cookies = document.cookie.split(";");

  // Iterate over each cookie
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].trim();

    // Check if the cookie starts with the given name
    if (cookie.indexOf(cookieName + "=") === 0) {
      // Return the decoded cookie value (substring after the equals sign)
      return decodeURIComponent(cookie.substring(cookieName.length + 1));
    }
  }

  // Return null if the cookie is not found
  return null;
}

export function deleteCookie(name, path) {
  document.cookie =
    name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=" + path;
}

export function customerLogin(LoginResponse) {
  const expirationTimeInMinutes = 6 * 60; // 6 hours in minutes

  Cookies.set("loggedIn", true, {
    // expires: expirationTimeInMinutes,
    secure: true,
  });
  Cookies.set("comverse_customer_id", LoginResponse?.id, {
    // expires: expirationTimeInMinutes,
    secure: true,
  });
  Cookies.set("comverse_customer_email", LoginResponse?.email, {
    // expires: expirationTimeInMinutes,
    secure: true,
  });
  Cookies.set("comverse_customer_token", LoginResponse?.token, {
    // expires: expirationTimeInMinutes,
    secure: true,
  });
}

export function customerLogout() {
  const cookiesCurrency = Cookies.get("defaultCurrencyName");
  const customerEmail = Cookies.get("comverse_customer_email");
  const customerId = Cookies.get("comverse_customer_id");
  if (customerEmail || customerId) {
    window.Moengage.track_event("User Logout", {
      Email: customerEmail ? customerEmail : " Not Found",
      "Customer ID": customerId ? customerId : " Not Found",
      Source: "Website",
      Currency: cookiesCurrency ? cookiesCurrency : "PKR",
    });
  }
  Cookies.remove("loggedIn");
  Cookies.remove("comverse_customer_id");
  Cookies.remove("comverse_customer_email");
  Cookies.remove("comverse_customer_token");
}

export default function AccountStatus() {
  const comverse_customer_token = Cookies.get("comverse_customer_token");

  // If the token is present, user is considered logged in
  if (comverse_customer_token) {
    return {
      loggedIn: true,
      comverse_customer_id: Cookies.get("comverse_customer_id"),
      comverse_customer_email: Cookies.get("comverse_customer_email"),
      comverse_customer_token: comverse_customer_token,
    };
  } else {
    return {
      loggedIn: false,
    };
  }
}
