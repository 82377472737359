"use client";
//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import {
  Col,
  Row,
  Space,
  Image,
  message,
  Button,
  Typography,
  Spin,
} from "antd";
import { signIn, useSession, signOut } from "next-auth/react";
import AccountApi from "@/Apis/AccountApi";
import { LoadingOutlined } from "@ant-design/icons";

import "./LoginPage.scss";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineGoogle } from "react-icons/ai";
import { ArrowLeftOutlined } from "@ant-design/icons";

import LoginForm from "../SignUpPage/Modules/LoginForm";
import SignupForm from "../SignUpPage/Modules/SignupForm";
import { loginReducer } from "@/redux/Slices/accountSlice";
import { customerLogin, customerLogout } from "@/functions/Cookies/Cookies";
import Loader from "../shared/Loader/Loader";
// import Loading from "@/app/loading";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function LoginPage() {
  const dispatch = useDispatch();
  const router = useRouter();
  const [ModalType, setModalType] = useState("login");
  const [ShowLoginModal, setShowLoginModal] = useState(true);
  const [loadingSpinner, setLoadingSpinner] = useState(true);

  const { data } = useSession();

  const userSocialLogin = async () => {
    setLoadingSpinner(true);
    const dataFromApi = await AccountApi.socialLogin({
      full_name: data?.user?.name,
      email: data?.user?.email,
      source: "google",
    }).then((data) => {
      return data;
    });
    if (dataFromApi?.status === 200) {
      customerLogin(dataFromApi?.data);
      dispatch(loginReducer(dataFromApi?.data));
      // setLoadingSpinner(false);
      router.push("/account");
    } else {
      setLoadingSpinner(false);
    }
  };

  useEffect(() => {
    if (data) {
      userSocialLogin(data);
    } else {
      setLoadingSpinner(false);
    }
  }, [data]);

  return loadingSpinner ? (
    <Loader />
  ) : (
    <Col className="LoginPage marginBottom25">
      <Col span={24} style={{ borderTop: "2px solid #E4E4E4" }}></Col>
      <Col span={24} className="marginTop10 marginBottom20">
        <Space size="middle">
          <Col
            style={{ background: "#FFFFFF", width: "29px", height: "30px" }}
            className="flexCenter"
          >
            <Link href="/">
              <ArrowLeftOutlined />
            </Link>
          </Col>
          <Text className="font18">Home</Text>
        </Space>
      </Col>

      <Col
        span={12}
        className="backgroundLight marginAuto padding15 flexColumn"
      >
        <>
          <Text className="font20Bold textCenter marginBottom10">
            {ModalType === "login" ? "Signin" : "Signup"}
          </Text>

          <Col span={24}>
            {ModalType === "login" ? (
              <LoginForm setShowLoginModal={setShowLoginModal} />
            ) : (
              <SignupForm setShowLoginModal={setShowLoginModal} />
            )}
          </Col>

          <Col span={18} className="marginAuto">
            <Row
              justify={"center"}
              align={"center"}
              gutter={[0, 7]}
              className="modalfooter"
            >
              <Col span={24} align="middle">
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    lineHeight: "21px",
                  }}
                >
                  {/* Or Login With */}
                </Text>
              </Col>
              <Col span={24} align="middle">
                <Space>
                  <Button
                    icon={
                      <AiOutlineGoogle
                        style={{ fontSize: "16px", color: "#cf4332" }}
                      />
                    }
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: "6px",
                    }}
                    onClick={() => {
                      signIn("google");
                    }}
                  >
                    Google
                  </Button>
                  <Button
                    icon={
                      <FaFacebookF
                        style={{ fontSize: "16px", color: "#337fff" }}
                      />
                    }
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      borderRadius: "6px",
                    }}
                    onClick={() => {
                      signIn("facebook");
                    }}
                  >
                    Facebook
                  </Button>
                </Space>
              </Col>
              <Col span={24} align="middle" style={{ marginTop: "20px" }}>
                {ModalType === "login" ? (
                  <Text
                    style={{
                      fontSize: "16px",
                      lineHeight: "25px",
                      fontWeight: "600",
                    }}
                  >
                    Create an Account at &nbsp;
                    <Text
                      style={{
                        color: "#FF3F6C",
                        fontSize: "16px",
                        lineHeight: "25px",
                        fontWeight: "600",
                        cursor: "pointer",
                        marginLeft: "-4px",
                      }}
                      onClick={() => {
                        setModalType("signup");
                      }}
                    >
                      Signup
                    </Text>
                  </Text>
                ) : (
                  <Text
                    style={{
                      fontSize: "16px",
                      lineHeight: "25px",
                      fontWeight: "600",
                    }}
                  >
                    {`Already have an account? `}
                    <Text
                      style={{
                        color: "#FF3F6C",
                        fontSize: "16px",
                        lineHeight: "25px",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setModalType("login");
                      }}
                    >
                      Signin
                    </Text>
                  </Text>
                )}
              </Col>
            </Row>
          </Col>
        </>
      </Col>
    </Col>
  );
}
