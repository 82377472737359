//? Library Imports ------------------------------------------------------------>
// import Script from "next/script";

//? Modules -------------------------------------------------------------------->
// import Homepage from "./pages/Homepage";
"use client";
import { useEffect, useState } from "react";
import LoginPage from "@/components/LoginPage/LoginPage";
// import UserAgent from "@/functions/UserAgent/UserAgent";
import LoginPageMobile from "@/components/MobileApp/Login/LoginPage";
import AccountStatus from "@/functions/Cookies/Cookies";
import { useRouter } from "next/navigation";
// import Loading from "@/app/loading";
import Loader from "@/components/shared/Loader/Loader";

//? Redux Reducers ------------------------------------------------------------->

export default function Home() {
  const [deviceName, setDeviceName] = useState(null);
  const [showtest, setshowtest] = useState(0);
  const router = useRouter();
  const { loggedIn, comverse_customer_token } = AccountStatus();
  // const { isDesktop, isTablet, isMobile } = UserAgent();
  useEffect(() => {
    if (loggedIn && comverse_customer_token) {
      router.push("/account");
    }
    setTimeout(() => {
      if (window.innerWidth < 1024) {
        setDeviceName("mobile");
      } else {
        setDeviceName("desktop");
      }
    }, 500);
  }, []);
  useEffect(() => {
    if (deviceName) {
      setshowtest(0);
    } else {
      setshowtest(1);
    }
  }, [deviceName]);

  return (
    <div>
      {deviceName ? (
        <>{deviceName === "desktop" ? <LoginPage /> : <LoginPageMobile />}</>
      ) : (
        <Loader />
      )}
    </div>
  );
}
